import { ModuleTypes } from "@/models/modules/modules.enum";
import { SolarModuleConfigBase } from "../solar-module-base/solar-module-config-base";
import { CarbonUnitsParameter } from "../units/carbon/carbon-units.parameter";

export class CarbonModuleConfig extends SolarModuleConfigBase {
  dataConverter = new CarbonUnitsParameter();

  constructor() {
    super(ModuleTypes.Carbon);
  }
}
