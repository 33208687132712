import { IDataModule } from "../parts/data-part/idata-module";
import { ITitledModule } from "../parts/title-part/ititled-module";
import { ParametersGroup } from "../parameters-group";
import { DataPartConfig } from "../parts/data-part/data-part.config";
import { TitlePartConfig } from "../parts/title-part/title-part.config";
import { UnitsParameterBase } from "../units/units-base.parameter";
import { ISolarModuleConfig } from "./isolar-module-config";
import { ModuleConfigBase } from "@/models/modules/module-base.config";
import { ModuleCategories } from "@/models/modules/module-categories.enum";
import { translate } from "@/utils/translate.utils";
import { ModuleTypes } from "@/models/modules/modules.enum";

export abstract class SolarModuleConfigBase
  extends ModuleConfigBase
  implements ITitledModule, IDataModule, ISolarModuleConfig
{
  category = ModuleCategories.Solar;

  titlePart!: TitlePartConfig;
  dataPart = new DataPartConfig();
  abstract dataConverter: UnitsParameterBase;

  constructor(type: ModuleTypes) {
    super(type);

    const defaultTitle = translate(`components.modules.${type}.title`);
    this.titlePart = new TitlePartConfig(defaultTitle);
  }

  getContentParameters(): ParametersGroup[] {
    const completeDataGroup = this.dataPart.getParameterGroup();

    completeDataGroup.parameters.push(this.dataConverter);

    return [this.titlePart.getParameterGroup(), completeDataGroup];
  }
}
