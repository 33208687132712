export class LightModuleDTO {
  id!: string;
  type!: string;
  posX!: number;
  posY!: number;
  width!: number;
  height!: number;
  zIndex!: number;
  elevation!: number;
  borderRadius!: number;
  backgroundColor!: string;

  constructor(init?: Partial<LightModuleDTO>) {
    Object.assign(this, init);
  }
}
