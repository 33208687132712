import { ModuleConfigBase } from "@/models/modules/module-base.config";
import { ModuleCategories } from "@/models/modules/module-categories.enum";
import { ModuleTypes } from "@/models/modules/modules.enum";
import { ParametersGroup } from "../parameters-group";
import { DataPartConfig } from "../parts/data-part/data-part.config";
import {
  TextParameterConfig,
  ParameterNames,
} from "@/models/modules/parameters";
import { i18n } from "@/vue-plugins/i18n";

export class TextModuleConfig extends ModuleConfigBase {
  category = ModuleCategories.Generic;

  textPart = new DataPartConfig();
  displayText = new TextParameterConfig(
    i18n.tc("components.modules.text.default"),
    ParameterNames.TextContent
  );

  constructor() {
    super(ModuleTypes.Text);
  }

  getContentParameters(): ParametersGroup[] {
    const textPart = this.textPart.getParameterGroup();

    textPart.parameters.unshift(this.displayText);

    return [textPart];
  }
}
