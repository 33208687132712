import {
  ZIndexParameterConfig,
  SliderParameterConfig,
  ScreenRelativeParameterConfig,
} from "@/models/modules/parameters";
import { ParameterBase } from "@/models/modules/parameters/parameter-base";
import { ParametersGroup } from "@/components/modules/parameters-group";
import { ParameterNames } from "@/models/modules/parameters/parameter-names.enum";
import { ModuleCategories } from "./module-categories.enum";
import { ModuleTypes } from "./modules.enum";
import { ParameterHolder } from "./parameters/parameter-holder";
import { ColorParameterConfig } from "@/models/modules/parameters/color.parameter";
import { Media } from "../files/media.model";
import { IModuleConfigBase } from "./imodule-config-base";

export abstract class ModuleConfigBase implements IModuleConfigBase {
  type!: ModuleTypes;
  abstract category: ModuleCategories;

  width = new ScreenRelativeParameterConfig(0.2, ParameterNames.Width, true);
  height = new ScreenRelativeParameterConfig(
    0.14,
    ParameterNames.Height,
    false
  );
  posX = new ScreenRelativeParameterConfig(0.5, ParameterNames.PosX, true);
  posY = new ScreenRelativeParameterConfig(0.5, ParameterNames.PosY, false);
  zIndex = new ZIndexParameterConfig(0, ParameterNames.ZIndex);
  elevation = new SliderParameterConfig(2, ParameterNames.Elevation, 0, 10, 1);
  borderRadius = new SliderParameterConfig(
    12,
    ParameterNames.BorderRadius,
    0,
    24,
    1
  );
  backgroundColor = new ColorParameterConfig(
    "#FFFFFFFF",
    ParameterNames.BackgroundColor
  );

  constructor(type: ModuleTypes) {
    this.type = type;
  }

  public loadParameters(toLoad: ParameterHolder[]): void {
    const flatParams = this.getParameters();

    toLoad.forEach((holder) => {
      const foundParam = flatParams.find((p) => p.name === holder.name);

      if (foundParam) {
        foundParam.loadValue(holder.value);
      }
    });
  }

  public loadMedias(medias: Media[]): void {
    //Nothing to do
  }

  public getParameters(): ParameterBase<any>[] {
    return this.getGroupedParameters()
      .map((p) => p.parameters)
      .flat();
  }

  public getGroupedParameters(): ParametersGroup[] {
    return [
      new ParametersGroup("components.configMenu.groups.general", [
        this.width,
        this.height,
        this.posX,
        this.posY,
        this.zIndex,
        this.elevation,
        this.borderRadius,
        this.backgroundColor,
      ]),
      ...this.getContentParameters(),
    ];
  }

  protected abstract getContentParameters(): ParametersGroup[];
}
