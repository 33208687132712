import { ModuleTypes } from "@/models/modules/modules.enum";
import { SolarModuleConfigBase } from "../solar-module-base/solar-module-config-base";
import { PowerUnitsParameter } from "../units/power/power-units.parameter";

export class InstantPowerModuleConfig extends SolarModuleConfigBase {
  dataConverter = new PowerUnitsParameter();

  constructor() {
    super(ModuleTypes.InstantPower);
  }
}
