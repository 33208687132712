import { IDataModule } from "../parts/data-part/idata-module";
import { ITitledModule } from "../parts/title-part/ititled-module";
import { ParametersGroup } from "../parameters-group";
import { DataPartConfig } from "../parts/data-part/data-part.config";
import { TitlePartConfig } from "../parts/title-part/title-part.config";
import { ModuleConfigBase } from "@/models/modules/module-base.config";
import { ModuleTypes } from "@/models/modules/modules.enum";
import { ModuleCategories } from "@/models/modules/module-categories.enum";
import { translate } from "@/utils/translate.utils";

export class TreeModuleConfig
  extends ModuleConfigBase
  implements ITitledModule, IDataModule
{
  category = ModuleCategories.Solar;

  titlePart!: TitlePartConfig;
  dataPart = new DataPartConfig();

  constructor() {
    super(ModuleTypes.Tree);

    const defaultTitle = translate("components.modules.tree.title");

    this.titlePart = new TitlePartConfig(defaultTitle);
  }

  getContentParameters(): ParametersGroup[] {
    return [
      this.titlePart.getParameterGroup(),
      this.dataPart.getParameterGroup(),
    ];
  }
}
