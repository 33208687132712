import { ITitledModule } from "../parts/title-part/ititled-module";
import { ParametersGroup } from "../parameters-group";
import { ProgressPartConfig } from "../parts/progress-part/progress-part.config";
import { TitlePartConfig } from "../parts/title-part/title-part.config";
import { IProgressModule } from "../parts/progress-part/iprogress-module";
import { ModuleConfigBase } from "@/models/modules/module-base.config";
import { ModuleCategories } from "@/models/modules/module-categories.enum";
import { translate } from "@/utils/translate.utils";
import { ModuleTypes } from "@/models/modules/modules.enum";

export abstract class TitledProgressModuleConfigBase
  extends ModuleConfigBase
  implements ITitledModule, IProgressModule
{
  category = ModuleCategories.Solar;
  titlePart!: TitlePartConfig;
  progressPart = new ProgressPartConfig();

  constructor(type: ModuleTypes) {
    super(type);

    const defaultTitle = translate(`components.modules.${type}.title`);
    this.titlePart = new TitlePartConfig(defaultTitle);
  }

  getContentParameters(): ParametersGroup[] {
    return [
      this.titlePart.getParameterGroup(),
      this.progressPart.getParameterGroup(),
    ];
  }
}
