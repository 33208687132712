import { ModuleTypes } from "@/models/modules/modules.enum";
import { SolarModuleConfigBase } from "../solar-module-base/solar-module-config-base";
import { EnergyUnitsParameter } from "../units/energy/energy-units.parameter";

export class DailyConsumptionConfig extends SolarModuleConfigBase {
  dataConverter = new EnergyUnitsParameter();

  constructor() {
    super(ModuleTypes.DailyConsumption);
  }
}
